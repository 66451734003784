import { render, staticRenderFns } from "./LoginCallback.vue?vue&type=template&id=e50f2eca&"
import script from "./LoginCallback.vue?vue&type=script&lang=js&"
export * from "./LoginCallback.vue?vue&type=script&lang=js&"
import style0 from "./LoginCallback.vue?vue&type=style&index=0&id=e50f2eca&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports