<template></template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import Ripple from "vue-ripple-directive";

export default {
  created() {
    let referrer_id = ""
      if (localStorage.getItem("mastertube_referral")) {
        referrer_id = localStorage.getItem("mastertube_referral");
      }
    this.$http
      .get(`/api/login/google/back`, {
        params: {
          code: this.$route.query.code,
          state: this.$route.query.state,
          scope: this.$route.query.scope,
          referrer_id:referrer_id
        },
      })
      .then((resp) => {
        this.user = resp.data.user;
        this.token = resp.data.token;
        this.$VueCookies.set("mastertube", resp.data.token);
        let searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has("redirect")) {
          window.open(
            process.env.VUE_APP_Url + `${searchParams.get("redirect")}`,
            "_self"
          );
        } else window.open(process.env.VUE_APP_Url, "_self");
      })
      .catch((error) => {
        this.$VueCookies.remove("mastertube");
        this.$router.push("/login").catch(() => {});
      });
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BAlert,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {},
  methods: {
    login() {
      this.makeToast(this.$t("Base.Alert.Successful"), this.$t("Base.Alert.please_wait"), "success");
      const payload = {
        email: this.userEmail,
        password: this.password,
      };
      this.$http
        .post(`/api/login`, payload)
        .then((resp) => {
          this.sendEmail = false;
          this.user = resp.data.user;
          this.token = resp.data.token;
          this.$VueCookies.set("mastertube", resp.data.token);
          let searchParams = new URLSearchParams(window.location.search);
          if (searchParams.has("redirect")) {
            window.open(
              process.env.VUE_APP_Url + `${searchParams.get("redirect")}`,
              "_self"
            );
          } else window.open(process.env.VUE_APP_Url, "_self");
        })
        .catch((error) => {
          if (error.response.status == 502) {
            this.sendEmail = true;
            this.makeToast(
              this.$t("Base.Alert.Successful"),
              this.$t("Base.Alert.activation_email_send"),
              "success"
            );
            this.$VueCookies.remove("mastertube");
          } else {
            this.sendEmail = false;
            this.makeToast(
              this.$t("Base.Alert.Error"),
              this.$t("Base.Alert.username_pass_incorrect"),
              "danger"
            );
            this.$VueCookies.remove("mastertube");
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
